<template>
  <div>
    <HeaderIndex />
    <BackgroundMain/>
    <MainContents/>
    <FooterMain/>
  </div>
</template>

<script>
import HeaderIndex from './components/HeaderIndex.vue'
import BackgroundMain from './components/BackgroundMain.vue'
import MainContents from './components/MainContents.vue'
import FooterMain from './components/FooterMain.vue'
export default {
  name: 'home-page',
  components: {
    HeaderIndex,
    BackgroundMain,
    MainContents,
    FooterMain
  }
}
</script>

<style lang="less" scoped>

</style>