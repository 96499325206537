<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="mainTitle">
          <h3 class="anim">软件定制开发</h3>
          <p class="anim">专业、安全的一站式定制软件设计、开发服务</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-3 anim" v-for="(item, i) in dataList" :key="i">
        <div class="mainDiv mainHeight">
          <div class="img"><img :src="item.img"></div>
          <h3>{{item.title}}</h3>
          <p class="textLeft">{{item.text}}</p>
          <p class="textLeft disNone2">{{item.main}}</p>
        </div>
      </div>
    </div>
    <div class="row disNone">
      <div class="col-xs-12 col-sm-12 col-md-12 anim" v-for="(item, i) in dataList" :key="i">
        <div class="mainDiv mainDiv2">
          <h3>{{item.title}}</h3>
          <p>{{item.main}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'MainContents',
  data () {
    return {
      dataList: [
        { img: require('@/assets/image/erp.png'), title: 'ERP+OA管理系统', text: '提供统一的业务信息化管理平台， 进行大数据分析',
          main: 'ERP系统是什么？是指企业资源计划系统。ERP是一个实现信息集成的管理系统，是企业内部的所有业务部门直接或者企业与外部合作伙伴之间交换和分享信息的系统。OA系统是什么？是企业内各个部门协同工作的产物，主要是解决企业内部dao事务性处理，辅助管理，提高办公效率和管理水平。'
        },
        { img: require('@/assets/image/wxg.png'), title: '微信公众号开发', text: '满足企业政务微信公众号开发需求，助力客户开拓微信用户市场',
          main: '利用公众账号平台进行自媒体活动，简单来说就是进行一对多的媒体性行为活动，如商家通过申请公众微信服务号通过二次开发展示商家微官网、微会员、微推送、微支付、微活动、微报名、微分享、微名片等，已经形成了一种主流的线上线下微信互动营销方式。'
        },
        { img: require('@/assets/image/wxx.png'), title: '小程序开发', text: '基于微信架构，微信小程序开发制作，行业小程序开发解决方案',
          main: '小程序是腾讯推出的一个战略级企业生态底层框架，每次新型企业生态底层框架的推出都将引起用户使用场景的变化，用户场景的变化必将引起互联网行业重新洗牌，小程序是一种不需要下载安装即可使用的应用，它实现了应用“触手可及”的梦想，用户扫一扫或者搜一下即可打开应用。随时可用，但又无需安装卸载。小程序将利用微信10多亿的用户群体，为企业带来新型营销方式和巨大流量红利。根据企业商家的实际需求和行业特点每个项目，都由产品经理亲自参与设计，量身定制出微信小程序解决方案，助力企业商家移动创意营销新体验。'
        },
        { img: require('@/assets/image/wxxs.png'), title: '小程序商城开发', text: '微信商城开发，热门行业案例，专业开发流程, 和数据分析',
          main: '让用户无需下载进行下单购买和支付，畅享购物的快乐。云店小程序商城 多业务场景支持 满足不同企业需求，提供多行业、多用途、可按需定制的商城小程序帮助企业拓展更多业务模式。'
        }
      ],
      windowHeight: ''
    }
  },
  mounted () {
    this.windowHeight=$(window).height();//绿色框可视区域的高度
    const _this = this
    $(window).resize(() => {
      _this.windowHeight = $(window).height()//缩放窗口这个高度会改变，需要再取得
    })
    $(window).scroll(() => {
      _this.roll()
    });
    _this.roll()
  },
  methods: {
    roll () {
      var dTop = $(document).scrollTop();//绿色框可视区域上面到黑色页面顶部的距离，会实时改变
      const anim = document.querySelectorAll('.anim')
      anim.forEach((item) => {
        if (dTop + this.windowHeight - 50 > $(item).offset().top - 50){
          $(item).addClass("anim-show");
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.row {
  margin: 0 -10px;
}
.mainTitle{
  text-align: center;
  padding: 20px;
  margin-top: 15px;
  h3 {
    font-size: calc(1.2rem + 1vw);
    font-size: -webkit-calc(1.2rem + 1vw);
    font-size: -moz-calc(1.2rem + 1vw);
    color: rgb(90, 175, 255);
  }
  p {
    padding: 20px 0;
    font-size: calc(0.7rem + 1vw);
    font-size: -webkit-calc(0.7rem + 1vw);
    font-size: -moz-calc(0.7rem + 1vw);
    color: #bdbdbd
  }
}
.mainHeight {
  min-height:  250px;
}
.mainDiv {
  position: relative;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 5px 5px 10px #cccccc;
  transition: 0.5s;
  background: #fff;
  &:hover{
    transform: scale(1.03);
    z-index: 5;
  }
  .img{
    text-align: center;
    padding: 20px 0 10px;
  }
  h3 {
    font-weight: bold;
    padding: 5px 0 10px;
  }
  p {
    line-height: 25px;
    font-size: 0.9rem;
    color: #666;
  }
}
.mainDiv2{
  text-align: left;
  padding: 20px;
  h3{
    text-align: center;
  }
}
.container {
  margin-bottom: 20px;
}
.anim-show {
position:relative;
animation-name:mymove;
animation-duration:1s;/* 5s表示执行动画的时间，0或不写则无动画效果 */
/* 兼容调试如果animation-name执行，那么-wekit-animation-name则不执行 */
-webkit-animation-name:mymove;
-webkit-animation-duration:1s;/* 5s表示执行动画的时间，0或不写则无动画效果 */
}
@keyframes mymove /* 对应animation-name，里面为执行的动画*/
{
from {top:200px;}/*执行动画的初始位置*/
to {top:0px;}/*动画结束位置*/
0% {
opacity: 0.1; /*初始状态 透明度为10%*/
}
50% {
opacity: 0.5; /*中间状态 透明度为50%*/
}
100% {
opacity: 1; /*结尾状态 不透明*/
}
}

@-webkit-keyframes mymove/* 对应-webkit-animation-name，里面为执行的动画*/
{
from {left:200px;}/*执行动画的初始位置*/
to {left:0px;}/*动画结束位置*/
}
@media (min-width: 768px) {
  .disNone2 {
    display: none;
  }
  .textLeft {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .disNone {
    display: none;
  }
  .textLeft {
    text-align: left;
  }
  .disNone2 {
    display: block;
  }
}
</style>