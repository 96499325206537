<template>
  <div class="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-8">
          <div class="footer-font">
            <div class="child">
              <p>邮箱: cs@gzmis.cn</p>
              <p>地址：广州市黄埔区保利鱼珠广场A-2栋2502房</p>
              <p>广州兴皓网络科技有限公司 © 2020 - {{data}}
                <span class="show_or_hide"> | </span><br class="hide_or_show" />
                <a target="_blank" rel="nofollow" href="https://beian.miit.gov.cn/">粤ICP备2022070526号</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4">
          <div class="imgright">
            <img :src="img" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'FooterMain',
  data () {
    return {
      data: new Date().getFullYear(),
      img: require('@/assets/image/qrcode.jpg')
    }
  }
}
</script>

<style lang="less" scoped>
.footer-copyright {
  padding: 20px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7386;
  // text-align: center;
  background: rgba(248, 248, 248);
  .footer-font {
    line-height: 25px;
    height: 100px;
    width: 100%;
    display: table;
    .child {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
.imgright{
  text-align: right;
  img{
    width: 100px;
  }
}
.hide_or_show {
  display: none;
}
@media (max-width: 991px) {
  .footer-copyright {
    line-height: 24px;
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .footer-font {
    height: 100% !important;
    padding-bottom: 10px;
  }
  .imgright, .footer-font {
    text-align: center;
  }
}
@media (max-width: 460px) {
  .show_or_hide {
    display: none;
  }
  .hide_or_show {
    display: block;
  }
}
</style>