<template>
  <header class="header">
    <div class="h-fix">
      <div class="container">
        <h1 class="logo">
          <a href="/">
            <img id="light-logo" :src="logoImg" alt="兴皓logo" title="兴皓logo">
          </a>
        </h1>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderIndex',
  data () {
    return {
      logoImg: require('@/assets/image/LOGO.png')
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: block;
  height: 78px;
  position: relative;
  left: 0;
  // -webkit-transition: all .3s ease;
  // -o-transition: all .3s ease;
  // transition: all .3s ease;
  z-index: 9;
  .h-fix {
    position: fixed;
    z-index: 9999;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 3px rgb(26 26 26 / 10%);
  }
  .logo {
    float: left;
    overflow: hidden;
    height: 78px;
    a {
      min-width: 120px;
      height: 100%;
      display: block;
      position: relative;
      font-size: 0;
      overflow: hidden;
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
}
#light-logo, #light-flogo {
  display: block;
}

img {
  max-width: 100%;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .header, .header .logo {
    height: 56px;
    display: inline-block;
    margin: 0 auto;
    font-size: 0;
  }
}
@media only screen and (max-width: 992px) {
  .header {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 898;
  }
}
@media only screen and (max-width: 992px) {
  .header, .header .logo {
    height: 56px;
  }
}
@media only screen and (max-width: 768px) {
  .header .container {
    text-align: center;
  }
}
@media only screen and (max-width: 992px) {
  .wrapper, .header .container {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 1200px) {
  .wrapper, .header .container, .crumb {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 768px) {
  .header .logo a {
    max-width: 90px;
  }
}
@media only screen and (max-width: 992px) {
  .header .logo a {
    min-width: 100px;
  }
}
</style>