<template>
  <div id="banner-bear" class="preserve3d csstransforms3d">
    <div class="bear_title">
      <p class="h3_title">创造</p>
      <p class="h4_title">无限可能</p>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'FooterMain',
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>

#banner-bear {
  // height: 80vh;
  background: url('../../../assets/image/login_default.jpg') no-repeat;
  background-size: 100% auto;
  width: 100%;
  position: relative;
  font-size: 22px;
  &::before {
    content: ""; 
    display: block; 
    padding-top: 50%;
  }
  .bear_title {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 90%;
    letter-spacing:5px;
    transform: translateY(-50%);
    .h3_title {
      font-size: calc(0.8rem + 3vw);
      color: rgb(90, 175, 255);
      animation:fadeInLeft 0.8s ease;
    }
    .h4_title {
      margin-top: 2%;
      padding-left: 5%;
      font-size: calc(0.5rem + 3vw);
      color: rgb(90, 175, 255);
      animation:fadeInLeft 1s ease;
    }
  }
}
@media (max-width: 992px) {
  #banner-bear {
    margin-top: 56px;
    background-position: top right;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%,0,0);
    transform: translate3d(-100%,0,0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
</style>